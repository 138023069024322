import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Slider from "react-slick";
import Link from 'next/link';
import Image from 'next/image';
import HomeNoticiasImage from "./HomeNoticiasImage";

const HomeNoticias = (props) => {
    const homeNews = props?.homeNews?.data;
    
    const slider_setting_homenews = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,

      responsive: [
        {
          breakpoint: 1140,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 765,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            rows: 2
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return (
          <>
            <div className="container box--wrap marg heading-container">
            <h2 className="A-la-venta-esta-sema ">Actualidad</h2>
            <div className="line4"></div>
            </div>
            <div className="container-fluid">
            <section className="regular image_slider_homenews">
              <div className="homepage_news_slider_wrapper">
                <Slider {...slider_setting_homenews}>
                    {
                        homeNews?.map( (item, i) => (
                            <Link passHref={true} key={`noticia${i}`} href={`/contenidos/${item.slug}`}>
                                <a className="contentLinks" target={item.newWindow==1 ? '_blank' : '_self'}>
                                    <div className="noticias">
                                        <div className="image_slider_homenews_img">
                                            <HomeNoticiasImage imagesrc={item.image}/>
                                        </div>
                                        <div className="connoticias">
                                            <h1>{item?.title}</h1>
                                            <div className="home_news_description" dangerouslySetInnerHTML={{__html: item?.shortContent}} ></div>
                                            <div>{moment(item?.publicationDate).format('DD/MM/YYYY')}</div>
                                        </div>                                
                                    </div>
                                </a>
                            </Link>
                        ))
                    }
                </Slider>
                </div>
            </section>
        </div>
        </>
    )
}

export default HomeNoticias;
