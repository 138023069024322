import React,{useRef} from 'react';
import useInViewport from '../../lib/hooks/useInViewport';
import Image from "next/image";

const HomeNoticiasImage = ({imagesrc}) => {

    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');

    return(

        <div className={`zoom-in ${inViewport? 'animation' : ''}`} ref={ref}>
            <Image quality={100} src={process.env.NEXT_PUBLIC_IMAGE_PATH+imagesrc} alt="" layout="responsive" sizes="100vw" width={353} height={203} loading="eager"></Image>
        </div>

    )
}

export default HomeNoticiasImage;
